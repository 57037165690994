<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.46967 3.96967C5.76256 3.67678 6.23744 3.67678 6.53033 3.96967L9 6.43934L11.4697 3.96967C11.7626 3.67678 12.2374 3.67678 12.5303 3.96967C12.8232 4.26256 12.8232 4.73744 12.5303 5.03033L9.53033 8.03033C9.23744 8.32322 8.76256 8.32322 8.46967 8.03033L5.46967 5.03033C5.17678 4.73744 5.17678 4.26256 5.46967 3.96967ZM12.5303 14.0303C12.2374 14.3232 11.7626 14.3232 11.4697 14.0303L9 11.5607L6.53033 14.0303C6.23744 14.3232 5.76256 14.3232 5.46967 14.0303C5.17678 13.7374 5.17678 13.2626 5.46967 12.9697L8.46967 9.96967C8.76256 9.67678 9.23744 9.67678 9.53033 9.96967L12.5303 12.9697C12.8232 13.2626 12.8232 13.7374 12.5303 14.0303Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
import { Vue } from "vue-class-component";

export default class ChevronDownUp extends Vue {}
</script>
